.subscription-plans {
    max-width: 1000px;
    margin: 0 auto;
    padding: 40px 20px;
    background: linear-gradient(135deg, #6a11cb 0%, #2575fc 100%);
    border-radius: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  .plans-title {
    font-size: 36px;
    font-weight: bold;
    color: white;
    text-align: center;
    margin-bottom: 40px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .plans-container {
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
  }
  
  .plan-card {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    padding: 30px;
    width: 400px;
    transition: all 0.3s ease;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .plan-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  }
  
  .plan-content {
    flex-grow: 1;
  }
  
  .plan-name {
    font-size: 24px;
    font-weight: bold;
    color: white;
    margin-bottom: 10px;
  }
  
  .plan-price {
    font-size: 36px;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 20px;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  }
  
  .plan-features {
    list-style-type: none;
    padding: 0;
    margin-bottom: 30px;
  }
  
  .feature-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
    color: white;
  }
  
  .feature-icon {
    margin-right: 10px;
    color: #ffffff;
    flex-shrink: 0;
  }
  
  .feature-icon-svg {
    width: 24px;
    height: 24px;
    filter: drop-shadow(0 0 2px rgba(255, 255, 255, 0.5));
  }
  
  .feature-text {
    font-size: 14px;
    line-height: 1.4;
  }
  
  .select-plan-btn {
    background-color: var(--plan-color);
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 25px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 100%;
    margin-top: auto;
  }
  
  .select-plan-btn:hover {
    opacity: 0.9;
    transform: scale(1.05);
  }
  
  .supported-platforms {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 40px;
  }
  
  .supported-platforms svg {
    width: 40px;
    height: 40px;
    color: white;
    filter: drop-shadow(0 0 4px rgba(255, 255, 255, 0.5));
  }
  
  @media (max-width: 900px) {
    .plan-card {
      width: 100%;
      max-width: 400px;
    }
  }
  
  