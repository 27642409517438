:root {
    --primary-color: #7a3e9b;
    --secondary-color: #37305E;
    --background-color: #f5f5f5;
    --card-background: #ffffff;
    --text-color: #333333;
  }
  
  body {
    background-color: var(--background-color);
    color: var(--text-color);
    font-family: 'Arial', sans-serif;
  }
  
  .instagram-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
  }
  
  h1 {
    text-align: center;
    color: white;
    font-size: 2.5rem;
    margin-bottom: 40px;
  }
  
  .accounts-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 30px;
  }
  
  .account-card {
    background-color: var(--card-background);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
  }
  
  .account-card:hover {
    transform: translateY(-5px);
  }
  
  .facebook-info, .instagram-info, .no-instagram {
    padding: 20px;
    text-align: center;
  }
  
  .facebook-info {
    background-color: var(--secondary-color);
    color: white;
  }
  
  .instagram-info {
    background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
    color: white;
  }
  
  .profile-picture {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 10px;
    border: 3px solid white;
  }
  
  h2, h3 {
    margin: 0 0 10px;
    font-size: 1.5rem;
  }
  
  p {
    margin: 5px 0;
  }
  
  .username {
    font-style: italic;
    opacity: 0.8;
  }
  
  .account-type {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 0.8rem;
    letter-spacing: 1px;
    margin-top: 10px;
  }
  
  .no-instagram {
    background-color: #f0f0f0;
    color: #666;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  