.leaderboard {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background: linear-gradient(135deg, #6a11cb 0%, #2575fc 100%);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .leaderboard-title {
    font-size: 28px;
    font-weight: bold;
    color: white;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .leaderboard-entries {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .leaderboard-entry {
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 15px;
    border-radius: 8px;
    transition: all 0.3s ease;
  }
  
  .leaderboard-entry:hover {
    transform: scale(1.02);
    background-color: rgba(255, 255, 255, 0.2);
  }
  
  .entry-icon {
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
  }
  
  .icon {
    width: 24px;
    height: 24px;
  }
  
  .icon.gold {
    color: #ffd700;
  }
  
  .icon.silver {
    color: #c0c0c0;
  }
  
  .icon.bronze {
    color: #cd7f32;
  }
  
  .icon.default {
    color: #2575fc;
  }
  
  .entry-info {
    flex-grow: 1;
  }
  
  .entry-username {
    font-size: 18px;
    font-weight: bold;
    color: white;
    margin: 0;
  }
  
  .entry-points {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
    margin: 5px 0 0;
  }
  
  .entry-rank {
    width: 30px;
    height: 30px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: white;
  }
  
  