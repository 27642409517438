.schedule-container {
    display: flex;
    justify-content: center;
    padding: 20px;
  }
  
  .form, .posted-content {
    margin: 10px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  textarea {
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
  }
  
  input {
    width: 60%;
    padding: 10px;
    margin-bottom: 10px;
  }

  .pages-container{
    display: flex;
    justify-content: space-around;
    padding: 20px;
  }

  .pages-container div{
    width: fit-content;
    padding: 20px;
    background-color: #7a3e9b;
    border-radius: 10px;
    cursor: pointer;
    color: white;
  }
  
