.profile-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .profile-header {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .profile-picture {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .edit-button, .connect-button, .settings-button {
    display: inline-block;
    margin: 10px 5px;
    padding: 10px 20px;
    background-color: purple;
    color: white;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .edit-button:hover, .connect-button:hover, .settings-button:hover {
    background-color: #7a3e9b;
  }
  
  .social-media-accounts, .account-settings, .profile-stats {
    margin-bottom: 30px;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  ul li {
    margin-bottom: 10px;
  }
  