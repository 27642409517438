.topbar{
    width: 90%;
    margin: auto;
    display: flex;
    gap: 30px;
}

.topbar div{
    width: 200px;
    height: 45px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-image: linear-gradient(to left, #37305E, #FF4177);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
}

.analytics-container{
    width: 90%;
    margin: auto;
    height: 90vh;
    overflow: auto;
    border: 2px solid #37305E;
    background-color: #333;
    text-align: left;
    padding: 40px;
}

.analytics-para{
    display: flex;
    align-items: center;
    font-size: 22px;
    gap: 10px;
    color: rgb(219, 219, 219);
}

.icons-container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 30px;
}

.icons-wrapper{
    text-align: center;
    margin-top: 30px;
    padding: 40px;
    background-color: #140f33;
    border: 2px solid #FF4177;
    border-radius: 20px;
}

.icons-container div{
    background-image: linear-gradient(to top, #37305E, #FF4177);
    width: 150px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-size: 20px;
}

.graph-container{
    text-align: center;
    margin-top: 30px;
    padding: 40px;
    background-color: #140f33;
    border: 2px solid #FF4177;
    border-radius: 20px;
}



.page-views{
    width: 20px;
    height: 20px;
    background-color: #FF5733;
    display: inline-block;
}

.page-impressions{
    width: 20px;
    height: 20px;
    background-color: #33A1FF;
    display: inline-block;
}

.post-engagement{
    width: 20px;
    height: 20px;
    background-color: #28A745;
    display: inline-block;
}

.graph-tags{
    text-align: left;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 40px;
}

/* Add these styles to your existing Analytics.css file */

/* Pulse animation for skeleton loaders */
@keyframes pulse {
    0% {
      opacity: 0.6;
    }
    50% {
      opacity: 0.8;
    }
    100% {
      opacity: 0.6;
    }
  }
  
  .pulse {
    animation: pulse 1.5s infinite ease-in-out;
  }
  
  /* Skeleton loaders */
  .topbar-skeleton {
    display: flex;
    gap: 10px;
    padding: 10px;
  }
  
  .skeleton-item {
    height: 30px;
    width: 100px;
    background-color: #e0e0e0;
    border-radius: 4px;
  }
  
  .header-skeleton {
    margin-bottom: 20px;
  }
  
  .skeleton-title {
    height: 40px;
    width: 60%;
    background-color: #e0e0e0;
    border-radius: 4px;
    margin-bottom: 15px;
  }
  
  .skeleton-text {
    height: 20px;
    width: 40%;
    background-color: #e0e0e0;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  
  .icon-skeleton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    width: 150px;
  }
  
  .skeleton-icon {
    height: 40px;
    width: 40px;
    background-color: #e0e0e0;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .skeleton-text-sm {
    height: 16px;
    width: 80px;
    background-color: #e0e0e0;
    border-radius: 4px;
    margin-bottom: 8px;
  }
  
  .skeleton-graph {
    height: 300px;
    width: 100%;
    background-color: #e0e0e0;
    border-radius: 8px;
  }
  
  .skeleton-tag {
    height: 20px;
    width: 120px;
    background-color: #e0e0e0;
    border-radius: 4px;
  }
  
  .graph-tags {
    display: flex;
    gap: 15px;
    margin-bottom: 15px;
  }
  
  /* Active page indicator */
  .topbar div.active {
    background-color: rgba(255, 65, 119, 0.1);
    border-bottom: 2px solid #ff4177;
  }
  
  /* No data container */
  .no-data-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 300px;
    color: #666;
  }
  
  .animate-spin {
    animation: spin 1s linear infinite;
    color: #ff4177;
    margin-bottom: 15px;
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  