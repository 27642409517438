.schedule-container {
    display: flex;
    justify-content: center;
    padding: 20px;
  }
  
  .form, .posted-content {
    margin: 10px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  textarea {
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
  }
  
  input {
    width: 60%;
    padding: 10px;
    margin-bottom: 10px;
  }

  .pages-container{
    display: flex;
    justify-content: space-around;
    padding: 20px;
  }

  .pages-container div{
    width: fit-content;
    padding: 20px;
    background-color: #7a3e9b;
    border-radius: 10px;
    cursor: pointer;
    color: white;
  }

  .info-box {
    width: 60%;
    margin: auto;
    background-color: #e8f5fe;
    border-radius: 12px;
    padding: 1.5rem;
    margin-bottom: 2rem;
    display: flex;
    align-items: flex-start;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .info-icon {
    color: #3498db;
    margin-right: 1rem;
    flex-shrink: 0;
  }
  
  .info-content {
    flex-grow: 1;
  }
  
  .info-content p {
    margin: 0 0 0.5rem;
    color: #333;
  }
  
