.toggler span{
    display: block;
    color: white;
    width: 150px;
    text-align: center;
    padding: 5px;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.3s;
}

.inner-toggle .selected{
    background-color: rgba(255, 255, 255, 0.717);
    color: black;
}

.inner-toggle{
    display: flex;
    justify-content: space-around;
    width: 90%;
    margin: auto;
}

.toggler{
    width: 400px;
    background-image: linear-gradient(to left, #37305E, #FF4177);
    padding: 8px;
    margin: auto;
    margin-bottom: 20px;
    border-radius: 8px;
    border: 2px solid white;
}

.loader-container{
    width: 75vw;
}

.content-container{
    width: 250px;
    background-image: linear-gradient(to top, #37305E, #FF4177);
    color: white;
    border-radius: 10px;
    text-align: left;
    height: 440px;
    overflow: hidden;
    box-shadow: 0px 0px 6px #37305E;
    border: 2px solid #37305E;
}
.text-container{
    padding: 20px;
}

.content-container video{
    background-color: black;
}

.content-container img, .content-container video{
    width: 100%;
    height: 200px;
}

.content-container p{
    margin-bottom: 10px;
}

.content-container hr{
    background-image: linear-gradient(to right, white, #FF4177);
    border: none;
    height: 1.5px;
    border-color: black;
    outline: none;
}

.content-grid{
    display: flex;
    justify-content: start;
    gap: 50px;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 50px;
}

.status{
    background-color: rgba(23, 197, 23, 0.555);
    padding: 2px 5px;
    border-radius: 20px;
    font-size: 11px;
    font-weight: 700;
}

.yellow{
    background-color: rgba(247, 227, 113, 0.701);
}

.filter-bar{
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-image: linear-gradient(to right, #37305E, #FF4177);
    box-shadow: 0px 0px 6px #FF4177;
    border-radius: 10px;
    border: 1px solid white;
}

.filter-bar input{
    width: 200px;
    background-color: #37305E;
    border-color: #FF4177;
    color: white;
}

.filter-bar .media-filter{
    width: 200px;
}

.filter-bar button{
    background-image: linear-gradient(to left, #333, #37305E);
    color: white;
    font-size: 14px;
    border: 1px solid #fff;
}



.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    gap: 10px;
  }
  
  .pagination-button {
    background-color: #37305e;
    color: white;
    border: none;
    padding: 10px 4px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .pagination-button:hover {
    background-color: #4a4178;
  }
  
  .pagination-button.active {
    background-color: #6c5ce7;
    font-weight: bold;
  }
  
  .pagination-button:disabled {
    background-color: #2c2649;
    cursor: not-allowed;
    opacity: 0.7;
  }
  
  .pagination-info {
    color: #fff;
    font-size: 14px;
    margin-top: 5px;
    text-align: right;
  }
  
  