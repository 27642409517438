.challenge-panel {
    background: linear-gradient(to top, #37305E, #FF4177);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    color: white;
    font-family: 'Arial', sans-serif;
    border-radius: 15px;
    border: 2px solid white;
    width: 80%;
    margin: auto;
  }

  .panel-title {
    font-size: 2rem;
    margin-bottom: 2rem;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 3px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  }

  .challenge-container {
    display: flex;
    gap: 2rem;
    flex-grow: 1;
  }

  .challenge-list {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow-y: auto;
    padding-right: 1rem;
  }

  .challenge-item {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 15px;
    padding: 1.5rem;
    font-size: 17px;
    transition: all 0.3s ease;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .challenge-item:hover, .challenge-item.selected {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    background: rgba(255, 255, 255, 0.2);
  }

  .challenge-text {
    flex-grow: 1;
  }

  .challenge-arrow {
    font-size: 1.5rem;
    opacity: 0;
    transition: all 0.3s ease;
  }

  .challenge-item:hover .challenge-arrow, .challenge-item.selected .challenge-arrow {
    opacity: 1;
    transform: translateX(10px);
  }

  .challenge-details {
    flex: 1;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 15px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .selected-title {
    font-size: 2rem;
    margin-bottom: 1rem;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  }

  .selected-description {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    line-height: 1.6;
  }

  .start-button {
    background: #FF4177;
    color: white;
    border: none;
    padding: 1rem 2rem;
    font-size: 1.2rem;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    align-self: flex-start;
    width: 100%;
  }

  .start-button:hover {
    background: #FF6B9B;
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  }

  .no-selection {
    font-size: 1.5rem;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
  }

  .loading, .error {
    text-align: center;
    font-size: 1.5rem;
    color: #FF4177;
    margin-top: 2rem;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  }

  /* Custom scrollbar for Webkit browsers */
  .challenge-list::-webkit-scrollbar {
    width: 8px;
  }

  .challenge-list::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
  }

  .challenge-list::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 10px;
  }

  .challenge-list::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.5);
  }

  .green{
    color: rgb(100, 255, 100);
  }

  .points{
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #37305E;
    color: white;
    gap: 10px;
    width: fit-content;
    border-radius: 30px;
    border: 2px solid palevioletred;
  }

  .panel-header{
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  @media (max-width: 768px) {
    .challenge-container {
      flex-direction: column;
    }

    .challenge-list, .challenge-details {
      width: 100%;
    }
  }