.schedule-container {
    display: flex;
    justify-content: center;
    padding: 20px;
  }
  
  .form, .posted-content {
    margin: 10px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  textarea {
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
  }
  
  input {
    width: 60%;
    padding: 10px;
    margin-bottom: 10px;
  }

  .pages-container{
    display: flex;
    justify-content: space-around;
    padding: 20px;
  }

  .pages-container div{
    width: fit-content;
    padding: 20px;
    background-color: #7a3e9b;
    border-radius: 10px;
    cursor: pointer;
    color: white;
  }
  
  
.hashtags-container {
  margin: 15px 0;
  width: 100%;
}

.hashtags-filter {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.hashtag-search,
.hashtag-filter {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.hashtag-search {
  flex: 1;
}

.hashtags-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.hashtag-pill {
  background-color: #e1f5fe;
  color: #0288d1;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.2s ease;
}

.hashtag-pill:hover {
  background-color: #b3e5fc;
  transform: translateY(-2px);
}

.hashtag-count {
  background-color: #0288d1;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  margin-left: 6px;
}

/* Ensure these styles work with your existing CSS */
.schedule-container {
  display: flex;
  gap: 20px;
  padding: 20px;
}

.form {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form textarea {
  min-height: 100px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.form button {
  background-color: #0288d1;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.form button:hover {
  background-color: #0277bd;
}

.posted-content {
  flex: 1;
  border-left: 1px solid #eee;
  padding-left: 20px;
}


.search-results {
  margin-top: 10px;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.user-result {
  padding: 8px 12px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
}

.user-result:hover {
  background-color: #333;
}

.user-name {
  margin: 0;
  font-weight: 500;
}

.selected-user-hashtags {
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #0288d1;
  color: black;
}