.content-library-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .library-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .upload-button {
    padding: 10px 20px;
    background-color: purple;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .filter-sort {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .filter-sort label {
    margin-right: 20px;
  }
  
  .content-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
  }
  
  .content-item {
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .content-item img {
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
  }
  
  .content-details {
    text-align: left;
  }
  
  .edit-button, .delete-button {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: purple;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .edit-button {
    margin-right: 5px;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination button {
    padding: 10px 20px;
    margin: 0 5px;
    background-color: purple;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  